<template>
<div class="usermain">
  <el-card>
    <el-row>
      <el-col :span="8">
        <el-input placeholder="请输入用户姓名搜索" v-model="username" clearable>
          <el-button slot="append" icon="el-icon-search" @click="onSearch">搜索</el-button>
        </el-input>
      </el-col>
      <el-col :span="10">
        <div></div>
      </el-col>
      <el-col :span="6" style="float: right;text-align: right">
        <el-button type="success" icon="el-icon-circle-plus-outline" round @click="onAddUser">新增</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="Username" label="姓名"></el-table-column>
          <el-table-column prop="Deptname" label="部门"></el-table-column>
          <el-table-column prop="Unitname" label="单位"></el-table-column>
          <el-table-column prop="Username" label="角色" :formatter="formatter"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="light" content="编辑" placement="top" :hide-after="hideafter">
                <el-button
                    type="primary"
                    icon="el-icon-edit-outline"
                    circle
                    @click="handleEdit(scope.$index, scope.row)">
                </el-button>
              </el-tooltip>
              <el-tooltip effect="light" content="删除" placement="top" :hide-after="hideafter">
                <el-button
                    style="margin-left: 30px"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="handleDelete(scope.$index, scope.row)">
                </el-button>
              </el-tooltip>
              <el-tooltip effect="light" content="重置密码" placement="top" :hide-after="hideafter">
                <el-button
                    style="margin-left: 30px"
                    type="danger"
                    icon="el-icon-unlock"
                    circle
                    @click="handleReset(scope.$index, scope.row)">
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="角色操作">
            <template slot-scope="scope">
              <el-tooltip effect="light" content="编辑角色" placement="top" :hide-after="hideafter">
                <el-button
                    type="primary"
                    icon="el-icon-s-custom"
                    circle
                    @click="handleEditRole(scope.$index, scope.row)">
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="astotalcount">
    </el-pagination>
  </el-card>

  <!--新增用户 弹出框操作begin-->
  <el-dialog
      title="新增用户"
      :visible.sync="dialogVisible"
      width="40%"
      @close="oncloseaddUser">
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
      <el-form-item label="姓名" prop="username">
        <el-input v-model="addForm.username"></el-input>
      </el-form-item>
      <el-form-item label="登录名" prop="loginname">
        <el-input v-model="addForm.loginname"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="addForm.password"></el-input>
      </el-form-item>
      <el-form-item label="单位" prop="unitid">
        <el-select v-model="addForm.unitid" placeholder="请选择单位" @change="onunitselectchange">
          <el-option
              v-for="item in unitoptions"
              :key="item.Id"
              :label="item.Unitname"
              :value="item.Id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门" prop="deptid">
        <el-select v-model="addForm.deptid" placeholder="请选择部门">
          <el-option
              v-for="item in deptoptions"
              :key="item.Id"
              :label="item.Deptname"
              :value="item.Id">
          </el-option>
        </el-select>
      </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onsaveuser">确 定</el-button>
  </span>
  </el-dialog>
  <!--新增用户 弹出框操作end-->

  <!--编辑用户 弹出框操作begin-->
  <el-dialog
      title="修改用户"
      :visible.sync="editdialogVisible"
      width="40%"
      @close="oncloseeditUser">
    <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
      <el-form-item label="姓名" prop="username">
        <el-input v-model="editForm.editusername"></el-input>
      </el-form-item>
      <el-form-item label="登录名" prop="loginname">
        <el-input v-model="editForm.editloginname" disabled></el-input>
      </el-form-item>
      <el-form-item label="单位" prop="unitid">
        <el-select v-model="editForm.editunitid" placeholder="请选择单位" @change="onunitselectchange">
          <el-option
              v-for="item in unitoptions"
              :key="item.Id"
              :label="item.Unitname"
              :value="item.Id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门" prop="deptid">
        <el-select v-model="editForm.editdeptid" placeholder="请选择部门">
          <el-option
              v-for="item in deptoptions"
              :key="item.Id"
              :label="item.Deptname"
              :value="item.Id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="editdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onedituser">确 定</el-button>
  </span>
  </el-dialog>
  <!--编辑用户 弹出框操作end-->

  <!--编辑角色 弹出框操作begin-->
  <el-dialog
      :title="'编辑角色-'+editroleusername"
      :visible.sync="roledialogVisible"
      width="40%"
      @close="oncloseaddRole">
    <el-transfer v-model="rolevalue" :data="roledata" :titles="['角色列表', '用户角色']"></el-transfer>
    <span slot="footer" class="dialog-footer">
    <el-button @click="roledialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onsaverole">确 定</el-button>
  </span>
  </el-dialog>
  <!--编辑角色 弹出框操作end-->


</div>
</template>

<script>
import {
  AddUserRole,
  GetAllRoleList,
  GetDeptList, GetSearchUser,
  GetUnitList, GetUserAllRole,
  GetUserList, GetUserRole,
  postAddUser,
  PostDeleteUser,
  postEditUser,
  ResetPwd
} from "@/network/user";

export default {
  name: "User",
  created() {
    //初始化人员角色列表
    this.inituserrole()
    //初始化角色列表
    this.initrole()
    //获取用户数据
    this.getUser(this.page,this.pagesize)
    //获取单位列表
    this.getunitlist()
  },
  data(){
    return{
      username:'',//搜索框绑定值
      tableData: [],//用户列表
      page:1,//分页
      pagesize:10,//分页数量
      astotalcount:0,//总计条数
      dialogVisible:false,//新增对话框
      //新增用户表单字段
      addForm:{
        username:'',
        loginname:'',
        password:'',
        unitid:'',
        deptid:''
      },
      //新增用户字段验证规则
      addFormRules:{
        username: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字', trigger: 'blur' }
        ],
        loginname: [
          { required: true, message: '请输入用于登录系统的账号', trigger: 'blur' },
          { min: 4, max: 15, message: '长度在 4 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ],
        unitid: [
          { required: true, message: '请选择单位', trigger: 'change' }
        ],
        deptid: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ]
      },
      //单位列表
      unitoptions:[],
      //部门列表
      deptoptions:[],
      //按钮提示关闭时间
      hideafter:3000,
      //修改用户对话框
      editdialogVisible:false,
      //修改用户字段
      editForm:{
        editusername:'',
        editloginname:'',
        editunitid:'',
        editdeptid:'',
        userid:''
      },
      //修改用户字段验证规则
      editFormRules:{
        editusername: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字', trigger: 'blur' }
        ],
        editloginname: [
          { required: true, message: '请输入用于登录系统的账号', trigger: 'blur' },
          { min: 4, max: 15, message: '长度在 4 到 15 个字符', trigger: 'blur' }
        ],
        editunitid: [
          { required: true, message: '请选择单位', trigger: 'change' }
        ],
        editdeptid: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ]
      },
      //角色列表
      userrolelist:[],
      //角色弹窗
      roledialogVisible:false,
      rolevalue:[],
      roledata:[],
      editroleusername:'',
      editroleuserid:'',
    }
  },
  methods:{
    //搜索
    onSearch(){
      if(this.username ===''){
        this.getUser(this.page,this.pagesize)
      }else
      {
        GetSearchUser(this.$store.state.token,this.page,this.pagesize,this.username).then(res => {
          console.log(res.pagecount)
          this.tableData =res.data
          this.astotalcount =res.pagecount
        }).catch(err => {
          console.log(err);
        })
      }
    },
    //新增用户
    onAddUser(){
      this.dialogVisible=true
    },
    //编辑用户按钮点击
    handleEdit(index, row) {
      this.editdialogVisible =true
      this.editForm.editusername=row.Username
      this.editForm.editloginname=row.Loginname
      this.editForm.editunitid=row.Unitid
      this.getdeptlist(row.Unitid)
      this.editForm.editdeptid=row.Deptid
      this.editForm.userid =row.Id
    },
    //删除用户
    handleDelete(index, row) {
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        PostDeleteUser(this.$store.state.token,row.Id).then(res => {
          console.log(res)
          if (res.data===true) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message({
              type: 'warning',
              message: '删除失败!'
            });
          }
          //刷新数据
          this.getUser(this.page,this.pagesize)
        }).catch(err => {
          console.log(err);
        })
      }).catch(() => {

      });
    },
    //重置密码
    handleReset(index, row) {
      this.$confirm('此操作将重置用户密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ResetPwd(this.$store.state.token,row.Id).then(res => {
          if (res.isreset===true) {
            this.$message({
              type: 'success',
              message: res.message,
              duration:5000
            });
          }else{
            this.$message({
              type: 'warning',
              message: res.message,
            });
          }
        }).catch(err => {
          console.log(err);
        })
      }).catch(() => {

      });
    },
    //获取用户列表数据
    getUser(page,pagesize){
      GetUserList(this.$store.state.token,page,pagesize).then(res => {
        this.tableData =res.data
        this.astotalcount =res.pagecount
      }).catch(err => {
        console.log(err);
      })
    },
    //页码点击
    handleSizeChange(value){
      this.page =value
      this.getUser(this.page,this.pagesize)
    },
    //下一页，上一页点击
    handleCurrentChange(value){
      this.page =value
      this.getUser(this.page,this.pagesize)
    },
    //关闭新增对话框，清除验证
    oncloseaddUser(){
      this.$refs.addFormRef.resetFields()
    },
    //关闭编辑对话框，清除验证
    oncloseeditUser(){
      this.$refs.editFormRef.resetFields()
    },
    //点击确认新增用户
    onsaveuser(){
      this.$refs.addFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('username', this.addForm.username);
        frmDate.append('password', this.addForm.password);
        frmDate.append('deptid', this.addForm.deptid);
        frmDate.append('unitid', this.addForm.unitid);
        frmDate.append('loginname', this.addForm.loginname);
        postAddUser(this.$store.state.token,frmDate).then(res => {
          if (res.isadd==false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.dialogVisible=false
            this.getUser(this.page,this.pagesize)
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
    //编辑用户信息
    onedituser(){
      this.$refs.editFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('username', this.editForm.editusername);
        frmDate.append('userid', this.editForm.userid);
        frmDate.append('deptid', this.editForm.editdeptid);
        frmDate.append('unitid', this.editForm.editunitid);
        frmDate.append('loginname', this.editForm.editloginname);
        postEditUser(this.$store.state.token,frmDate).then(res => {
          console.log(res);
          if (res.isedit==false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.editdialogVisible=false
            this.getUser(this.page,this.pagesize)
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
    //获取单位列表数据
    getunitlist(){
      GetUnitList(this.$store.state.token).then(res => {
        this.unitoptions =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    //获取部门列表数据
    getdeptlist(unitid){
      GetDeptList(this.$store.state.token,unitid).then(res => {
        this.deptoptions =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    //点击单位选中框，触发部门数据变动
    onunitselectchange(value){
      this.getdeptlist(value)
    },
    //格式化角色列
    formatter(row, column){
      let rolename =''
      for (let item of this.userrolelist) {
        if (row.Id ===item.Userid){
          rolename+=item.Rolename+','
        }
      }
      return rolename.length>0?rolename.substring(0,rolename.length-1):rolename
    },
    inituserrole(){
      GetUserAllRole(this.$store.state.token).then(res => {
        this.userrolelist =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    handleEditRole(index,row){
      this.rolevalue=[]
      this.roledialogVisible =true
      this.editroleusername =row.Username
      this.editroleuserid =row.Id
      for (let item of this.userrolelist) {
        if (row.Id ===item.Userid){
          this.rolevalue.push(item.Roleid)
        }
      }
    },
    onsaverole(){
      let roidlist =''
      for (let item of this.rolevalue) {
        roidlist+=item+','
      }
      roidlist = roidlist.length>0?roidlist.substring(0,roidlist.length-1):roidlist
      AddUserRole(this.$store.state.token,roidlist,this.editroleuserid).then(res => {
        if (res.data === true){
          this.roledialogVisible=false
          this.$message({
            message: res.Msg,
            type: 'success'
          })
          this.inituserrole()
          this.getUser(this.page,this.pagesize)
        }else{
          this.$message({
            message: res.Msg,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err);
      })

    },
    initrole(){
      GetAllRoleList(this.$store.state.token).then(res => {
        this.roledata =[]
        for (let item of res.data){
          this.roledata.push({
            key: item.Roleid,
            label: item.Rolename,
            disabled:false
          });
        }
        console.log(this.roledata)
      }).catch(err => {
        console.log(err);
      })
    },
    oncloseaddRole(){
      this.rolevalue=[]
    },
  }
}
</script>

<style scoped>
.usermain{
  padding: 0px;
}

.el-table{
  margin: 20px 0px;
}
.el-select{
  width: 100%;
}
</style>